/* eslint-disable react/jsx-no-undef */
import React, { useState, useRef } from 'react';
import { Stage, Layer, Rect, Line, Text, Image } from 'react-konva';
import useImage from 'use-image';
import { Grid, Button } from '@mui/material';
import CarList from '../Lists/CarList';
import Ball from '../Ball';
import BlueCar from '../../models/BlueCar';
import OrangeCar from '../../models/OrangeCar';
// import SaveIcon from '@mui/icons-material/Save';
// import LockResetIcon from '@mui/icons-material/LockReset';

export default function Canvas() {
    // Constants
    const ballHeight = 125;
    const ballWidth = 125;
    const startingXPos = 713;
    const startingYPos = 455;
    const carHeight = 125;
    const carWidth = 125;

    // const blueCarList = [
    //     new BlueCar(1, "blueCar1", 150, 385),
    //     new BlueCar(2, "blueCar2", 150, 575),
    //     new BlueCar(3, "blueCar3", 310, 475),
    // ];

    // const orangeCarList = [
    //     new OrangeCar(1, "orangeCar1", 1310, 380),
    //     new OrangeCar(2, "orangeCar2", 1310, 570),
    //     new OrangeCar(3, "orangeCar3", 1140, 475),
    // ];
    
    // Hooks
    const [mapImage, status] = useImage('https://esportsgamelogos182317-production.s3.us-east-2.amazonaws.com/maps/rocketleague/rocket_league_field_image.png', 'anonymous');
    const [ballImage, ballStatus] = useImage('https://esportsgamelogos182317-production.s3.us-east-2.amazonaws.com/maps/rocketleague/rocket-league-ball.png', 'anonymous');
    const [blueCarImage, blueCarImageStatus] = useImage('https://esportsgamelogos182317-production.s3.us-east-2.amazonaws.com/maps/rocketleague/Octane_blue_full.png', 'anonymous');
    const [orangeCarImage, orangeCarImageStatus] = useImage('https://esportsgamelogos182317-production.s3.us-east-2.amazonaws.com/maps/rocketleague/Octane_orange_full.png', 'anonymous');

    const [isDragging, setIsDragging] = useState<boolean>(false);
    // const [x, setX] = useState<number>(0);
    // const [y, setY] = useState<number>(0);
    const [ballPositionX, setX] = useState<number>(0);
    const [orangeCarPosition, setOrangeCarPosition] = useState(0);
    const [blueCarPosition, setBlueCarPosition] = useState(0);
    const [blueCarList, setBlueCarList] = useState([
        new BlueCar(1, "blueCar1", 150, 385),
        new BlueCar(2, "blueCar2", 150, 575),
        new BlueCar(3, "blueCar3", 310, 475),
    ]);
    const [orangeCarList, setOrangeCarList] = useState([
        new OrangeCar(1, "orangeCar1", 1310, 380),
        new OrangeCar(2, "orangeCar2", 1310, 570),
        new OrangeCar(3, "orangeCar3", 1140, 475),
    ]);
    
    const [tool, setTool] = useState('pen');
    const [lines, setLines] = useState<Array<any>>([]);
    const isDrawing = useRef(false);
    const stageRef = useRef<any>(null);

    // Functions/Methods
    const enableDrawing = () => {
        isDrawing.current = true;
    };

    const disableDrawing = () => {
        isDrawing.current = false;
    };

    const downloadURI = (uri: string, name: string) => {
        var link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

    const handleDownloadPlay = () => {
        // TODO: This should be wired to a form that is displayed to name the play
        const playName = 'First Play Ever';
        const uri: string = stageRef?.current?.toDataURL();
        downloadURI(uri, `${playName}.jpg`);
    };

    const handleSavePlay = () => {
        console.log('RESET PLAY CLICKED');
    };

    const handleResetPlay = () => {
        console.log('RESET PLAY CLICKED');
        setLines([]);
        // Reset car positions
        resetCarPositions();
        // Reset the ball position to the center of the rocket league field.
        resetBallPosition();
    };

    const resetCarPositions = () => {
        const blueCarList: Array<BlueCar> = [
            new BlueCar(1, "blueCar1", 150, 385),
            new BlueCar(2, "blueCar2", 150, 575),
            new BlueCar(3, "blueCar3", 310, 475),
        ];

        const orangeCarList: Array<OrangeCar> = [
            new OrangeCar(1, "orangeCar1", 1310, 380),
            new OrangeCar(2, "orangeCar2", 1310, 570),
            new OrangeCar(3, "orangeCar3", 1140, 475),
        ];

        localStorage.setItem('blueCarPositions', JSON.stringify(blueCarList));
        localStorage.setItem('orangeCarPositions', JSON.stringify(orangeCarList));
    }

    const resetBallPosition = () => {
        console.log('RESET BALL POSITION CALLED');
    }

    const confirmResetPlay = () => {
        // TODO: Implement the logic needed for confirmResetPlay
    };

    //Enables drawing
    const handleMouseDown = (e: any) => {
        enableDrawing();
        const pos = e.target.getStage().getPointerPosition();
        setLines([...lines, { tool, points: [pos.x, pos.y] }]);
        if (e.target.attrs.image) {
            disableDrawing();
            // This gets the starting position of the image
            const startingPosition = e.target.getStage().getPointerPosition();
            console.log(
              `POSITION ON CLICK: ${startingPosition.x} ${startingPosition.y}`
            );
          } else {
            enableDrawing();
            // const pos = e.target.getStage().getPointerPosition();
            // console.log('POSITION: ', pos)
            // this.lines.push({
            //   id: Date.now(),
            //   points: [pos.x, pos.y],
            // });
          }
    }

    // Disables drawing
    const handleMouseUp = () => {
        disableDrawing();
    }

    const handleDraw = (e: any) => {
        // no drawing - skipping
        if (!isDrawing.current) {
            return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        let lastLine = lines[lines.length - 1];
        // add point
        lastLine.points = lastLine.points.concat([point.x, point.y]);
    
        // replace last
        lines.splice(lines.length - 1, 1, lastLine);
        setLines(lines.concat());
    }

    const handleOnBallDragStart = (e: any) => {
        console.log('ON BALL DRAG START', e)
    };

    const handleOnBallDragEnd = (e: any) => {
        console.log('ON BALL DRAG END', e)
    };

    const handleCarDragStart = (e: any, car: any) => {
        console.log('HANDLE CAR DRAG START', car);
        console.log('EVENT EMITTED', e);
    };

    // Single function that should handle both blue and orange car drag events to update the psoition
    const handleCarDragEnd = (e: any, car: any) => {
        console.log('HANDLE CAR DRAG END', car);
        console.log('EVENT EMITTED', e);

        // Update the position of the blue car in the list aka replace its position
        const dragEndPosition = e.target.getStage().getPointerPosition();
        console.log(' DRAG END POSITION: ', dragEndPosition);

        // These properties are being modified by reference which is a very, very bad practice this needs to be fixed to pass by value
        // Update the position of the car
        car.x = dragEndPosition.x;
        car.y = dragEndPosition.y;
        // replace the value in the array for the given blue car.
        console.log(car);
      }

    // useEffect(() => {
    // }, []);


    // UI Template/JSX
    return (
        <Grid>
            {/* TODO: I'd like to see if its possible to overlay the tools in the upper left. */}
            {/* <Grid item xs={12}>
                <Button onClick={handleSavePlay}>Save Play</Button>
                <Button onClick={handleResetPlay}>Reset Play</Button>
                <Button onClick={handleDownloadPlay}>Download Play</Button>
                <select value={tool}
                    onChange={(e) => {
                    setTool(e.target.value);
                    }}>
                    <option value="pen">Pen</option>
                    <option value="eraser">Eraser</option>
                </select>
            </Grid> */}
            <Grid item xs={12}>
                <Stage id="playCanvas" 
                       ref={stageRef}
                       width={window.innerWidth} 
                       height={window.innerHeight}
                       onMouseDown={handleMouseDown}
                       onMouseUp={handleMouseUp}
                       onMouseMove={handleDraw}
                       >
                    <Layer>
                        <Rect height={1080} width={1560} fillPatternImage={mapImage}  />
                        <Image name="ball" 
                                width={ballWidth} 
                                height={ballHeight} 
                                image={ballImage} 
                                draggable={true}
                                onDragStart={handleOnBallDragStart}
                                onDragEnd={handleOnBallDragEnd}
                                x={startingXPos}
                                y={startingYPos}
                            />
                        {/* <Ball /> */}
                        {/* <CarList /> */}
                        {/* TODO: Need to figure out a way to update the positions of the cars independently */}
                        <div>
                            { blueCarList.map((blueCar) => {
                                return <Image name={blueCar.name} 
                                            key={blueCar.id}
                                            image={blueCarImage}
                                            draggable={true}
                                            height={carHeight}
                                            width={carWidth}
                                            x={blueCar.x}
                                            y={blueCar.y}
                                            onDragStart={(event) => handleCarDragStart(event, blueCar)}
                                            onDragEnd={(event) => handleCarDragEnd(event, blueCar)}
                                />  
                            })}

                            { orangeCarList.map((orangeCar) => {
                                return <Image name={orangeCar.name}
                                            key={orangeCar.id}
                                            image={orangeCarImage}
                                            draggable={true}
                                            height={carHeight}
                                            width={carWidth}
                                            x={orangeCar.x}
                                            y={orangeCar.y}
                                            onDragStart={(event) => handleCarDragStart(event, orangeCar)}
                                            onDragEnd={(event) => handleCarDragEnd(event, orangeCar)}
                                />  
                            })}
                        </div>
                        { lines.map((line, i) => (
                            <Line key={i}
                                  points={line.points}
                                  stroke="#df4b26"
                                  strokeWidth={5}
                                  tension={0.5}
                                  lineCap="round"
                                  lineJoin="round"
                                  globalCompositeOperation={
                                  line.tool === 'eraser' ? 'destination-out' : 'source-over'
                            }
                            />
                        ))}
                    </Layer>
                </Stage>
            </Grid>
        </Grid>
      );
    }