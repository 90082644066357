// TODO: game service service handles all of the logic pertaining to the game service microservice
import React from 'react';
import { API } from 'aws-amplify';
import IGameService from '../interfaces/IGameService';

const apiName = 'games';
const apiPath = '/games';

export default class GameService implements IGameService {
    async getGamesAsync(): Promise<any> {
        try {
            const games = await API.get(apiName, apiPath, {});
            return games;
        } catch (error) {
            return error;
        }
    }
}