import { Auth } from 'aws-amplify';
import IAuthService from '../interfaces/IAuthService';

// The purpose of the AuthService is to act as a service layer for all ofour AWS Auth service calls
// TODO: Extract to library then re-implement library as service calls.
export default class AuthService implements IAuthService {
    async signInAsync(emailAddress: string, password: string): Promise<any> {
        try {
            const user = await Auth.signIn(emailAddress, password);
            return user;
        } catch(error: any) {
            return error;
        }
    }

    async signOutAsync(): Promise<any> {
        try {
            await Auth.signOut();
        } catch(error: any) {
            return error;
        }
    }

    async signUpAsync(user: any): Promise<void> {
        try {
            await Auth.signUp(user);
        } catch(error: any) {
            return error;
        }
    }

    async forgotPasswordSubmitAsync(emailAddress: string, verificationCode: string, password: string): Promise<any> {
        try {
            const testString = await Auth.forgotPasswordSubmit(emailAddress, verificationCode, password);
            console.log(testString)
        } catch(error: any) {
            return error;
        }
    }

    async forgotPassword(emailAddress: string): Promise<any> {
        try{
            await Auth.forgotPassword(emailAddress);
        } catch(error: any) {
            return error;
        }
    }
}