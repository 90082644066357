import { API } from 'aws-amplify';
import IContactUsService from '../interfaces/IContactUsService';

// TODO: Extract to library then re-implement library as service calls.
export default class ContactUsService implements IContactUsService {
    async postContactUs(contact: any) :Promise<any> {
        try {
            return await API.post('contactus', '/contactus', { body: contact })
        } catch(error: any) {
            return error;
        }
    }
}