import CircularProgress from '@mui/material/CircularProgress';


export default function CircularLoader(props: any) {
    if(props.isLoading) {
        return (
            <CircularProgress sx={{ margin: 'auto', justifyContent:'center'}}  />
        )
    } else {
        return null;
    }
}