import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, TextField, Grid, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";



export default function ConfirmAccount() {
    const navigate = useNavigate();
    const location = useLocation();
    const username = location.state.username;
    const [verificationCode, setVerificationCode] = useState('');

    const handleVerifyAccount = async() => {
        // TODO: We need to get the username stored in global state 
        await Auth.confirmSignUp(username, verificationCode).then((response: any) => {
            navigate('/playbooks');
        }).catch((error: any) => {
            console.log(error);
        })


    }

    const handleOnVerificationCodeChange = (e: any) => setVerificationCode(e.target.value)

    return (
        <Card sx={{ marginTop: 5}}>
            <CardHeader title='Confirm your esports playbook account' subheader='Enter verification code from the email received' />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField fullWidth={true} label='Verification Code' onChange={handleOnVerificationCodeChange} />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button onClick={handleVerifyAccount}>Verify Account</Button>
            </CardActions>
        </Card>
    )
}