/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "contactus",
            "endpoint": "https://jp9e70m9kb.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        },
        {
            "name": "games",
            "endpoint": "https://lp9o0ms7ej.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        },
        {
            "name": "plays",
            "endpoint": "https://rk8bbl5hzh.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        },
        {
            "name": "pricing",
            "endpoint": "https://3k82f4zcf9.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        },
        {
            "name": "playbooks",
            "endpoint": "https://kx8mt4ofwb.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:8b3ec9bb-f4b8-4400-a519-4af0f775bef0",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_ob7NygACd",
    "aws_user_pools_web_client_id": "46d27ajomee1c5iv2815brocnj",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER",
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "contactusDB-staging",
            "region": "us-east-2"
        },
        {
            "tableName": "gamesDB-staging",
            "region": "us-east-2"
        },
        {
            "tableName": "playbooksDB-staging",
            "region": "us-east-2"
        },
        {
            "tableName": "playsDB-staging",
            "region": "us-east-2"
        },
        {
            "tableName": "pricingDB-staging",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
