import React from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './views/Home';
import Pricing from './views/Pricing';
import ContactUs from './views/ContactUs';
import Games from './views/Games';
import ForgotPassword from './views/ForgotPassword';
import PlayCreator from './views/PlayCreator';
import Roster from './views/Roster';
import Plays from './views/Plays';
import Playbooks from './views/Playbooks';
import SignIn from './views/SignIn';
import ConfirmAccount from './views/ConfirmAccount';
import NavigationBar from './components/Navigation/NavigationBar';
import { Container } from '@mui/system';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import SignUp from './views/SignUp';
import { store } from './store/store';
import { Provider } from 'react-redux';
Amplify.configure(awsExports);


// TODO: We should extract this router logic to another file if possible
const router = createBrowserRouter([
  {
    path: '/',
    Component: Home
  },
  {
    path: '/pricing',
    Component: Pricing,
  },
  {
    path: 'games',
    Component: Games,
  },
  {
    path: '/contactus',
    Component: ContactUs,
  },
  {
    path: "/signin",
    Component: SignIn,
  },
  {
    path: '/signup',
    Component: SignUp,
  },
  {
    path: "/playbooks",
    Component: Playbooks,
  },
  {
    path: "/plays",
    Component: Plays,
  },
  {
    path: "/roster",
    Component: Roster,
  },
  {
    path: "/playcreator",
    Component: PlayCreator,
  },
  {
    path: "/forgotpassword",
    Component: ForgotPassword,
  },
  {
    path: "/confirmaccount",
    Component: ConfirmAccount,
  },
])

 export default function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <NavigationBar />
        <Container>
          <RouterProvider router={ router } />
        </Container>
      </Provider>
    </React.StrictMode>
  );
}