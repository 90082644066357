import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, TextField, Grid, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import { useNavigate } from "react-router-dom";
import AuthService from '../services/authService';


// TODO: We need to come up with the sign up flow for beta level access which should be free.
// We will need to set expectations that we will be switching to a paid service eventually.
// We would also want to implement stripe functionality for payments too.

export default function SignUp() {
    const navigate = useNavigate();
    const authService = new AuthService();
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [gamerTag, setGamerTag] = useState('');

    const handleFirstNameChange = (e: any) => setFirstName(e.target.value);
    const handleLastNameChange = (e: any) => setLastName(e.target.value);
    const handlePhoneNumberChange = (e: any) => setPhoneNumber(e.target.value);
    const handleEmailAddressChange = (e: any) => setEmailAddress(e.target.value);
    const handlePasswordChange = (e: any) => setPassword(e.target.value);
    const handleGamertagChange = (e: any) => setGamerTag(e.target.value);


    // Currently working code and originally written.
    const handleSignUp = () => {
        Auth.signUp({
            // Maps to email address
            username: `${emailAddress}`,
            // Maps to password
            password: `${password}`,
            attributes: {
                // Programmatically need to include given_name and family_name
                name: `${firstName} ${lastName}`,
                // map to email address
                email: `${emailAddress}`,
                // Maps to Phone number but we need +1 for anyone in the US.
                phone_number: `+1${phoneNumber}`,
                // Maps to first name
                given_name: `${firstName}`,
                // Maps to last name
                family_name: `${lastName}`,
                // Maps to gamer tag
                nickname: `${gamerTag}`
            }
        }).then((response: any) => {
            const userConfirmed = response.userConfirmed;
            const userName = response.user.username;

            if(!userConfirmed) {
                navigate('/confirmaccount', { state : { username: userName }});
            }
        }).catch((error: any) => {
            console.log('SIGN UP ERROR: ', error)
        })
    }

    // Work In progress for refactoring
    // const handleSignUp = async() => {
    //     const newUser = {
    //         username: `${emailAddress}`,
    //         password: `${password}`,
    //         attributes: {
    //             // Programmatically need to include given_name and family_name
    //             name: `${firstName} ${lastName}`,
    //             // map to email address
    //             email: `${emailAddress}`,
    //             // Maps to Phone number but we need +1 for anyone in the US.
    //             phone_number: `+1${phoneNumber}`,
    //             // Maps to first name
    //             given_name: `${firstName}`,
    //             // Maps to last name
    //             family_name: `${lastName}`,
    //             // Maps to gamer tag
    //             nickname: `${gamerTag}`
    //         }
    //     }
    //     await authService.signUp(newUser).then((response: any) => {
    //         console.log('SIGN UP', response)
    //         const userConfirmed = response.userConfirmed;
    //         const userName = response.user.username;

    //         if(!userConfirmed) {
    //             navigate('/confirmaccount', { state : { username: userName }});
    //         }
    //     }).catch((error: any) => {
    //         console.log('SIGN UP ERROR: ', error)
    //     })
    // }

    // TODO: Validate all of the user attributes before being able to submit.
    // TODO: Later we will introduce stripe for payment processing to sign up to the platform.
    return (
        <Card sx={{marginTop: 5}}>
            <CardHeader title={'Sign up for esports playbook beta access!'} subheader='All fields are required.' />
            <CardContent>
                <Grid container rowSpacing={1}>
                    <Grid item>
                        <p>Beta access means we are actively seeking feedback for our product. Not all features may work at this time so it is use at your own risk.</p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField fullWidth={true} label='First Name' onChange={handleFirstNameChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField fullWidth={true} label='Last Name' onChange={handleLastNameChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField fullWidth={true} label='Phone Number' onChange={handlePhoneNumberChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField fullWidth={true} label='Email Address' onChange={handleEmailAddressChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField fullWidth={true} label='Gamer Tag' onChange={handleGamertagChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField fullWidth={true} label='Password' onChange={handlePasswordChange} />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button onClick={handleSignUp}>Sign Up</Button>
            </CardActions>
        </Card>
    )
}