import React, { useState, useEffect } from 'react';
import { Card, CardActions, CardHeader, CardContent, Button, CardMedia } from '@mui/material';
import GameService from '../services/gameService';
import Game from '../models/Game';

export default function Games() {
    const gameService = new GameService();
    const [games, setGames] = useState([]);
    const [error, setErrror] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // TODO: Calling this from the UI is a very bad practices we should be calling a service layer that retreives and returns items from an API.
    const getGames = async() => {
        try {
            const games = await gameService.getGamesAsync();
            console.log('GAMES FETCHED FROM API: ', games);
            setGames(games);
        } catch (error) {
            console.log('ERROR GETTING GAMES FROM GAME SERVICE', games);
        }
    }

    useEffect(() => {
        getGames();
    }, []);

    // TODO: Display a loader until the games are displays and rendered from the API.
    return (
        <div>
            {
                games.map((game: Game) => {
                    return (
                    <Card key={game.id} sx={{marginTop: 5, maxWidth: 1000 }}>
                        <CardMedia sx={{height: 300, justifyContent: 'center'}} image={game.image_url} />
                    </Card>
                    )
                })
            }

            <Card sx={{ marginTop: 5}}>
                <CardHeader title={'Do not see the game that you play?'} subheader={'Let us know!'} />
                <CardContent>
                    <p>We are a small startup and have a vision for this product we are starting with Rocket League, however, we have plans to expand to more games soon!</p>
                </CardContent>
                <CardActions>
                    <Button href='/contactus'>Contact Us</Button>
                </CardActions>
            </Card>
        </div>
    )
}