import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import PBButton from '../components/Buttons/PBButton';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { Auth } from 'aws-amplify';
import CircularLoader from '../components/Loaders/CircularLoader';
import { useNavigate } from "react-router-dom";
import PBTextField from '../components/PBTextField';
import AuthService from '../services/authService';


// Things todo left
// We need to hook up the buttons to API calls for AWS Incognito.
// 2. Styling to ensure mobile responsiveness and form design consistency between the prod site and the newly developed react site.
// 3. We need to display an error label to the user when an error occurs
// 4. Display a progress loader when a network request is made.
export default function SignIn() {
    const authService = new AuthService();
    const navigate = useNavigate();
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState('');
    const [ isLoading, setIsLoading] = useState<boolean>(false);

    const onEmailAddressChange = (e: any) => setEmailAddress(e.target.value);
    const onPasswordChange = (e: any) => setPassword(e.target.value);
    
    const handleSignUp = () => signUp();
    const handleSignIn = () => signInAsync();
    const handleForgotPassword = () => forgotPasswordAsync();

     const signInAsync = async() => {
        try {
            setIsLoading(true);
            const user = await authService.signInAsync(emailAddress, password)
            if(user) {
                navigate('/playbooks');
            }
        } catch (error: any) {
            setErrorMessage(error.message)
            setIsLoading(false);
        }
    }

    const forgotPasswordAsync = async() => {
        setIsLoading(true);
        await Auth.forgotPassword(emailAddress).then(() => {
            navigate('/forgotpassword');
        }).catch((error: any) => {
            setErrorMessage(error.message);
            setIsLoading(false);
        })

        // TODO: We could use the response data for this call to build out a UI that has certain information before navigating or passing it on navigate through a state parameter.
        await authService.forgotPassword(emailAddress).then(() => {
            navigate('/forgotpassword');
        }).catch((error: any) => {
            setErrorMessage(error.message);
            setIsLoading(false);
        })
    }

    const signUp = () => {
        // passes the email address from sign up to sign in without having to use redux.
        navigate('/signup', { state : { emailAddress: emailAddress}} )
    }
    
    return (
        <Card sx={{ maxHeight: 1000, maxWidth: 1000, marginTop: 20 }}>
            <CardContent>
            <CardMedia sx={{ minHeight: 150, minWidth: 150, maxHeight: 500, maxWidth: 500, paddingBottom: 5, margin: 'auto', justifyContent: 'center'}}
                        component="img"
                        height='450'
                        width='450'
                        image="https://esportsgamelogos182317-production.s3.us-east-2.amazonaws.com/logos/ESPB_logo.png"
                        alt="ESPB Logo"
            />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PBTextField label='Email Address' onChange={onEmailAddressChange} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <PBTextField label="Password" onChange={onPasswordChange} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <label style={{ color: 'red'}}> { errorMessage }</label>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CircularLoader isLoading={isLoading} />
                </Grid>
            </Grid>
            </CardContent>
            <CardActions>
                <PBButton text="Sign Up" onClick={handleSignUp} />
                <PBButton text="Sign In" onClick={handleSignIn} />
                <PBButton text="Forgot Password" onClick={handleForgotPassword} />
            </CardActions>
        </Card>
    )
}