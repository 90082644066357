// TODO: All Imports here
import PricingList from "../components/Lists/PricingList"
import Subscription from "../models/Subscription"

const subscriptions: Array<Subscription> = [
        {
        id: 1,
        title: "Individual",
        subtitle: "Designed for individuals",
        misc: "Opening for early access in September 2022.",
        misc2: "Sign Up",
        route: "/signup",
      },
      {
        id: 2,
        title: "Team",
        subtitle: "Designed for teams for up to 10 users",
        misc: "Opening for early access in September 2022.",
        misc2: "Sign Up",
        route: "/signup",
      },
      {
        id: 3,
        title: "Gaming Organization",
        subtitle: "Designed for professional gaming organizations",
        misc: "Contact us for more details on early access",
        misc2: "Contact Us",
        route: "/contactus",
      },
];

export default function Pricing() {
    return (
        // TODO: subscriptions errors out for some reason we need to research this.
        // <PricingList subscriptions={subscriptions} />
        <p>Pricing List under construction</p>
    )
}