import Car from "./Car";

export default class BlueCar implements Car {
  id: number;
  name: string;
  x: number;
  y: number;

  constructor(id: number, name: string, x: number, y: number) {
    this.id = id;
    this.name = name;
    this.x = x;
    this.y = y;
  }
}
