import React, { useState, useEffect } from 'react';
import PlaybooksList from '../components/Lists/PlayBooksList';
import SearchBar from '../components/SearchBars/SearchBar';
import DropdownList from '../components/Dropdowns/DropdownList';
import { Grid } from '@mui/material';
import PlaybookService from '../services/playbookService';
import Playbook from '../models/Playbook';
import CircularLoader from '../components/Loaders/CircularLoader';

export default function Playbooks() {
    const playbookService = new PlaybookService();

    // Hooks
    const [playbooks, setPlaybooks] = useState<Array<Playbook>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        fetchPlaybooks();
    }, []);

    const fetchPlaybooks = async() => {
        setIsLoading(true);
        await playbookService.getPlaybooksAsync().then((playbooks: any) => {
            console.log('PLAYBOOKS RESPONSE: ', playbooks);
            if(playbooks) {
                setPlaybooks(playbooks)
                setIsLoading(false);
            }
        }).catch((error: any) => {
            console.log('PLAYBOOKS ERROR: ', error.message);
            setIsLoading(false);
        });
    }

    if(isLoading) {
        return (
            <Grid container columnSpacing={2} sx={{marginTop: 15}}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CircularLoader isLoading={isLoading} />
                </Grid>
            </Grid>
        ) 
    } else {
        return (
            <Grid container columnSpacing={2} sx={{ marginTop: 5}}>
                <Grid item xs={6} sm={6} md={6} lg={5}>
                    <SearchBar labelText="Search Playbooks" placeholderText="Search playbooks" />
                </Grid>
    
                <Grid item xs={6} sm={6} md={6} lg={6}>
                    <DropdownList />
                </Grid>
                <Grid item>
                    <PlaybooksList playbooks={playbooks} />
                </Grid>
            </Grid>
        )
    }
}