import React, { useState} from 'react';
import { Card, CardHeader, CardActions, CardContent, Button, TextField , Grid} from '@mui/material';
import ContactUsService from '../services/contactusService';

export default function ContactUs() {
    const contactUsService = new ContactUsService();
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const handleEmailOnChange = (e: any) => setEmailAddress(e.target.value);
    const handleSubjectOnChange = (e: any) => setSubject(e.target.value);
    const handleMessageOnChange = (e: any) => setMessage(e.target.value);

    const clearForm = () => {
        setEmailAddress('');
        setSubject('');
        setMessage('');
    }

    // TODO: We want to display a loader until the message comes back with something like a 204?
    // TODO: Clear the form after the message has been sent.
    const handleSendMessage = () => {
        const contact = {
            emailAddress,
            subject,
            message,
        }

        contactUsService.postContactUs(contact).then((res: any) => {
            if(res.success) {
                clearForm();
            }
        });
    }



    return (
        <Card sx={{ marginTop: 5}}>
            <CardHeader title='Contact Us' subheader={'Get in touch with us!'} />
            <CardContent>
            <p>
              Send us a message to give us feedback or submit ideas or join our
              discord and be a part of the esportsplaybook community!
            </p>
            <Button href='https://discord.com/channels/905560871529488406/905560872645189723'>Join our discord community!</Button>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <TextField fullWidth={true} label="Email" onChange={handleEmailOnChange} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField fullWidth={true} label="Subject" onChange={handleSubjectOnChange} />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField minRows={5} fullWidth={true} multiline={true} label="Message" onChange={handleMessageOnChange} />
                </Grid>
            </Grid>
            </CardContent>
            <CardActions>
                <Button onClick={handleSendMessage}>Send Message</Button>
            </CardActions>
        </Card>
    )
}