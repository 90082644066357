import React, { useState } from 'react';
import { Button, TextField, Grid, Card, CardHeader, CardContent, CardActions } from '@mui/material';
import CircularLoader from '../components/Loaders/CircularLoader';
import AuthService from '../services/authService';


export default function ForgotPassword() {
    const authService = new AuthService();
    const [emailAddress, setEmailAddress] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleOnEmailAddressChange = (e: any) => setEmailAddress(e.target.value);
    const handleOnVerificationCodeChange = (e: any) => setVerificationCode(e.target.value);
    const handleOnPasswordChange = (e: any) => setPassword(e.target.value);

    const handleResetPassword = async() => {
        setIsLoading(true);
        await authService.forgotPasswordSubmitAsync(emailAddress, verificationCode, password).then(() => {
            setErrorMessage('Successfull Reset Password')
        }).catch((error: any) => {
            setIsLoading(false);
            setErrorMessage(error.message);
        })
    }

    // TODO: We want to build in form validation to our inputs to validate the following
    // 1. Validate an email Address
    // 2. Validate that only numbers are being input
    // 3. Validate that the password is secure enough against AWS requirements.
    // 4. Do not allow the user to submit unless all of them are validated
    return (
        <Card sx={{marginTop: 5}}>
            <CardHeader title='Forgot Password' />
            <CardContent>
                <Grid container rowSpacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        To reset your password you should receive a code to your email address please use your email address, the verification code, and enter a new password.
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField label='Email Address' fullWidth={true} onChange={handleOnEmailAddressChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField label='Verification Code' fullWidth={true}  onChange={handleOnVerificationCodeChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField label='New Password'  fullWidth={true}  onChange={handleOnPasswordChange} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <label style={{color: 'red'}}> {errorMessage} </label>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CircularLoader isLoading={isLoading} />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button onClick={handleResetPassword} disabled={false}>Reset Password</Button>
            </CardActions>
        </Card>

    )
}