import React from 'react';
import { Card, CardHeader, CardActions, CardContent, Fab, Rating } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PlaybookService from '../../services/playbookService';

export default function PBCard(props: any) {
    const { playbook } = props;
    const playbookService = new PlaybookService();


    // Functions
    // Shows a modal form to show the edit playbook modal UI.
    const handleEditPlaybookModal = (playbook: any) => {
        // TODO: 
        console.log('NEED IMPLEMENTATION');
        console.log('GETTING PLAY BOOK DATA', playbook)
    }
    
    // Submits the data to the API to update the data of a given playbook.
    const handleEditPlaybookSubmit = async(playbook: any) => {
        console.log('Playbook to be updated: ', playbook);
        await playbookService.updatePlaybookAsync(playbook);
    }

    const handleDeletePlaybookSubmit = async(playbook: any) => {
        console.log('PLAYBOOK TO BE DELETED: ', playbook);
        //await playbookService.deletePlaybookAsync(playbook);
    }

    return (
        <Card sx={{ marginTop: 5, maxWidth: 350, maxHeight: 500}}>
            <CardHeader title={playbook.name} subheader={playbook.description} />
            <CardContent>
                <div>
                    <ul>
                    <li>Author: {playbook.author} </li>
                    <li>Date Created: {playbook.dateCreated} </li>
                    </ul>
                </div>
                <img src={playbook.imageUrl} height={220} width={220} alt="playImage" />
            </CardContent>
            <CardActions>
                {/* Book icon like the previous site or closets comparison */}
                <Fab size='medium' color='primary' aria-label='add'>
                    <EditIcon />
                </Fab>
                {/* Read only ratings at the right side of the card space between FAB and ratings */}
                {/* TODO: Create a custom spacer component */}
                <Rating />
            </CardActions>
        </Card>
    )
}