// Responsibility of trhe playbook service is to control all of the interactions at the playbook service layer.
import { API } from 'aws-amplify';
import Playbook from '../models/Playbook';
import IPlaybookService from '../interfaces/IPlaybookService';

const apiName = 'playbooks';
const apiPath = '/playbooks';

export default class PlaybookService implements IPlaybookService {
    /** Retrieves all of the playbooks from the dynamoDb table no paging. 
     *  @returns a Promise of any for now.
     * **/
    async getPlaybooksAsync(): Promise<any> {
        try {
            const playbooks = await API.get(apiName, apiPath, {});
            return playbooks;
        } catch (error: any) {
            return error;
        }
    }

    /** Retrieves all of the playbooks from the dynamoDb table no paging. 
     * @param id: number this should be the playbook Id.
     *  @returns a Promise of any for now.
     * **/
    async getPlaybookById(id: number): Promise<any> {
        try {
            const playbook = await API.get(apiName, apiPath, { id: id });
            console.log(playbook);
            return playbook;
        } catch (error: any) {
            console.log(error);
            return error;
        }
    }

    /** Creates a new playbook
     * @param playbook supply a playbook object to persist it to dynamo db.
     *  @returns a Promise of any for now.
     * **/
    async createPlaybookAsync(playbook: Playbook): Promise<void> {
        try {
            console.log('PLAYBOOK TO BE CREATED: ', playbook);
            await API.post(apiName, apiPath, { body: playbook});
        } catch (error: any) {  
            console.log('CREATE PLAYBOOK ERROR: ', error);
            return error;
        }
    }

    /** Updates an existing playbook
     * @param playbook supply a playbook object to persist it to dynamo db.
     *  @returns a Promise of any for now.
     * **/
    async updatePlaybookAsync(playbook: Playbook): Promise<void> {
        try {
            console.log('PLAYBOOK TO BE UPDATED', playbook);
            await API.put(apiName, apiPath, { body: playbook});
        } catch (error: any) {
            console.log('ERROR: ', error);
            return error;
        }
    }

    /** Deletes an existing playbook
     * @param playbook supply a playbook object to persist it to dynamo db.
     *  @returns a Promise of any for now.
     * **/
    async deletePlaybookAsync(playbook: Playbook): Promise<void> {
        try {
            console.log('PLAYBOOK TO BE DELETED', playbook);
            await API.del(apiName, apiPath, { body: playbook});
        } catch (error: any) {
            console.log('PLAYBOOK DELETE ERROR: ', playbook);
            return error;
        }
    }
}