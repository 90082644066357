import React from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import PlaybookService from '../../services/playbookService';

export default function PBFloatingActionButton() {
    const playbookService = new PlaybookService();
    const navigate = useNavigate();
    const handleCreateNewPlaybook = (e: any,) => {
        // TODO: Create the play first before navigating to the play creator
        // TODO: Once we get a successful creating then navigate to the play creator.
        // try {
        //     playbookService.createPlaybookAsync(playbook);
        //     navigate('/playcreator');
        // } catch (error: any) {
        //     console.log(error);
        // }
        navigate('/playcreator');
    } 

    return (
        <Fab style={{margin: 0, top: 'auto', right: 20, bottom: 20, left: 'auto', position: 'fixed'}} 
             size='medium' 
             color='primary' 
             aria-label='add'
            onClick={handleCreateNewPlaybook}>
            <AddIcon />
        </Fab>
    )
}