import React from 'react';
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";

export default function SearchBar(props: any, {setSearchQuery}: any) {
    return (
        <form>
            <TextField id='searchBar' className='text' onInput={(e: any) => setSearchQuery(e.target.value)}
                label={props.labelText}
                variant='outlined'
                placeholder={props.placeHolderText}
                fullWidth={true}
                size='medium' />
            <IconButton type='submit' aria-label='search'>
                <SearchIcon style={{fill: 'blue'}} />
            </IconButton>
        </form>
    )
}