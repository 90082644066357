import React, { useState, useEffect } from 'react';
import Playbook from '../../models/Playbook';
import PBCard from '../Cards/PBCard';
import { Grid } from '@mui/material';
import PBFloatingActionButton from '../FABs/PBFloatingActionButton';

export default function PlaybooksList(props: any) {
    const { playbooks } = props;

    // JSX
    return(
        // TODO: If the playbooks are loading present to the user that playbooks are loading.
        <Grid container spacing={2}>
            {
                playbooks.map((playbook: Playbook) => {
                    return (
                        <Grid item key={playbook.id}>
                            <PBCard playbook={playbook} />
                        </Grid>
                    )
                })
            }
 
            {/* TODO: Responsible for creating a new play */}
            <PBFloatingActionButton />
        </Grid>
    )
}