import React from 'react';
import Canvas from '../components/Canvas/Canvas';
import { Grid } from '@mui/material';

// TODO: We will want a way to display that the play creator is loading before while assets are being retrieved from the given api.
export default function PlayCreator() {
    return (
        <Grid container sx={{marginLeft: 0,}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Canvas />
            </Grid>
        </Grid>
    )
}