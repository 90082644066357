import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function DropdownList() {

    const [age, setAge] = useState<string>('');

    const handleSortPlaybooks = (e: SelectChangeEvent) => {
        setAge(e.target.value as string);
    }

    return (
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Sort Playbooks By</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Sort Playbooks By"
              onChange={handleSortPlaybooks}
            >
              <MenuItem value={10}>Highest Rating</MenuItem>
              <MenuItem value={20}>Lowest Rating</MenuItem>
              <MenuItem value={30}>Name (A-Z)</MenuItem>
              <MenuItem value={30}>Name (Z-A)</MenuItem>
            </Select>
          </FormControl>
        </Box>
      );
}