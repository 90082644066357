import { Toolbar } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import React from 'react';
import ESPBLogo from '../ESPBLogo';

export default function NavigationBar() {
    return (
        <AppBar position="static" style={{ backgroundColor: '#fff'}}>
        <Toolbar>
          <ESPBLogo />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {/* TODO: Display the current route */}
          </Typography>
          <Button style={{color: 'black'}} href='/' color="inherit">Home</Button>
          <Button style={{color: 'black'}} href='/pricing' color="inherit">Pricing</Button>
          <Button style={{color: 'black'}} href='/games' color="inherit">Games</Button>
          <Button style={{color: 'black'}} href='/contactus' color="inherit">Contact Us</Button>
          <Button style={{color: 'black'}} href='/signin' color="inherit">Sign In</Button>
        </Toolbar>
      </AppBar>
    )
}